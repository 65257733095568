import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import router from "../router";
import api from "../services/api";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    isAuthenticated: state.isAuthenticated,
    currentUser: state.currentUser,
    userBanned: state.userBanned,
    registrationIncomplete: state.registrationIncomplete,
    incompleteStep: state.incompleteStep,
    userNonsmoking: state.userNonsmoking,
  })
});

const checkSegment = value => {
  return typeof value !== 'undefined' && value !== null
};

export default new Vuex.Store({
  state: {
    isLoading: false,
    isMenuOpened: false,
    showErrorsBag: false,
    errors: [],
    isAuthenticated: false,
    currentUser: false,
    userBanned: false,
    userNonsmoking: false,
    deletionStatus: null,
    registrationIncomplete: false,
    incompleteStep: false,
    profileData: null,
    cigaretteMarks: null,
    userSegments: {
      alpha: false,
      betta: false,
    },
    ageConfirmed: false,
  },
  mutations: {
    loading(state, value) {
      state.isLoading = value;
    },
    setIsMenuOpened(state, value) {
      state.isMenuOpened = value;
    },
    setShowErrorsBag(state, value) {
      state.showErrorsBag = value;
    },
    setErrors(state, value) {
      if (value.length) {
        state.errors = value;
        state.showErrorsBag = true;
      }
    },

    login(state, userData) {
      console.log('login mutation');
      state.isAuthenticated = userData['auth'] === 'Success' || userData['SHOW_CONTENT'];
      state.deletionStatus = userData['deletionRequestStatus'];
      state.currentUser = userData;
      if (state.currentUser.segments) {
        state.userBanned =
          checkSegment(state.currentUser.segments.BlockedCustomers) ||
          checkSegment(state.currentUser.segments.DocumentsModerationFail)
      }
    },
    logout(state) {
      state.isAuthenticated = false;
      state.userBanned = false;
      state.currentUser = false;
      state.registrationIncomplete = false;
      state.incompleteStep = false;
      state.profileData = null;
    },
    setDeletionStatus(state, status) {
      state.deletionStatus = status;
    },
    setProfileData(state, profileData) {
      state.profileData = profileData;
    },
    setProfileDeletionStatus(state, profileData) {
      state.profileData.customFields.deletionRequestStatus = profileData;
    },
    setStateData(state, data) {
      state[data.state] = data.data
    },
    setUserNonsmoking(state) {
      state.userBanned = true;
      state.userNonsmoking = true;
    },
    setUserSegments(state, segments) {
      state.userSegments = segments;
    },
    setAgeConfirmed(state, status) {
      state.ageConfirmed = status;
    }
  },
  actions: {
    // UI
    showLoader({commit}) {
      commit('loading', true);
    },
    hideLoader({commit}) {
      commit('loading', false);
    },

    toggleMenu({state, commit}) {
      commit('setIsMenuOpened', !state.isMenuOpened);
    },
    showErrorsBag({commit}) {
      commit('setShowErrorsBag', true);
    },
    hideErrorsBag({commit}) {
      commit('setShowErrorsBag', false);
    },
    setErrors({commit}, errors) {
      let messages;
      if (
        typeof errors.errors !== 'undefined'
        && typeof errors.errors[0] !== 'undefined'
      ) {
        if (errors.errors[0].code === 0) {
          messages = [errors.errors[0].message];
        } else {
          messages = errors.errors[0].customData || [errors.errors[0].message];
        }
        commit('setErrors', messages);
      }
    },
    clearErrors({commit}) {
      commit('setErrors', []);
    },
    confirmAge({ commit }) {
      commit('setAgeConfirmed', true);
    },
    resetAgeConfirmation({ commit }) {
      commit('setAgeConfirmed', false);
    },
    async gohome() {
      await router.push({name: 'home'});
    },
    async goblocked() {
      await router.push({name: 'blocked'});
    },
    async goprofileupdate() {
      await router.push({name: 'profile-update'});
    },
    async goProfileForm() {
      await router.push({name: 'profile-fields'});
    },
    async goToInfoAboutPassportCheck(text) {
      await router.push({name: 'info-passport-check', params: {text: text}});
    },

    // User management
    // eslint-disable-next-line
    async login({commit, state}, credentials) {
      const result = await api.login(credentials);
      if (result) {
        /*if (result.segments && (
          checkSegment(result.segments.RegistrationStage2) ||
          checkSegment(result.segments.RegistrationStage2per) ||
          checkSegment(result.segments.DocumentsModerationInProcess))
        ) {
          let res = await api.simplePostRequest('/api/getRegistrationStep');
          state.registrationIncomplete = true;
          state.incompleteStep = res;
        }*/
        commit('login', result);
        return result;
      }
    },
    async checkAuthentication({commit}) {
      const authData = await api.checkAuthentication();
      if (authData) {
        commit('login', authData);
        /*if (authData.segments && (
          checkSegment(authData.segments.RegistrationStage2) ||
          checkSegment(authData.segments.RegistrationStage2per) ||
          checkSegment(authData.segments.DocumentsModerationInProcess))
        ) {
          const res = await api.simplePostRequest('/api/getRegistrationStep'); // @todo надо ли?
          state.registrationIncomplete = true;
          state.incompleteStep = res;
        }*/
        return true;
      } else {
        return false;
      }
    },
    async logout({commit}, logoutUrl) {
      if (logoutUrl) {
        await api.logout(logoutUrl);
      }
      commit('logout');
      if (router.currentRoute.name !== 'login') {
        await router.push('/login');
      }
    },
    async segmentsJump({state, dispatch}, step = false) {
      console.log('segmentsJump action');
      const segments = state.currentUser.segments;
      const canShowConsumption = state.currentUser.canShowConsumption || true;

      if (checkSegment(segments.alpha)) {
        dispatch('goprofileupdate');
        return;
      }

      if (checkSegment(segments.CheckConsumptionPart1) && canShowConsumption) {
        await router.push({name: 'consumption-survey'});
        return;
      }
      if (checkSegment(segments.CheckConsumptionPart2) && canShowConsumption) {
        await router.push({name: 'consumption-survey', params: {initStep: 'step2'}});
        return;
      }
      if (
        checkSegment(segments.BlockedCustomers) ||
        checkSegment(segments.DocumentsModerationFail)) {
        await router.push({name: 'blocked'});
        return;
      }
      if (checkSegment(segments.DocumentsModerationResend)) {
        if (step) {
          await router.push({name: 'register', params: {step: 2, redirect: step}});
          return;
        } else {
          await router.push({name: 'register', params: {step: 2}});
          return;
        }
      }
      if (checkSegment(segments.RegistrationStage2DocumentsFirst)
        ||checkSegment(segments.RegistrationStage2per)
      ) {
        await router.push({name: 'register', params: {step: 2}});
        return;
      }
      if (checkSegment(segments.DocumentsModerationInProcess)) {
        if (checkSegment(segments.RegistrationStage2)) {
          await router.push({name: 'register', params: {step: step || 8}});
          return;
        } else {
          dispatch('gohome');
        }
      }
      if (checkSegment(segments.DocumentsModerationSuccess)) {
        dispatch('gohome');
      }

      console.log('segmentsJump ended with gohome');

      dispatch('gohome');
    },
    async fetchProfile({commit, dispatch, state}) {
      dispatch("showLoader");
      try {
        const profileData = await api.fetchProfile();
        if (profileData) {
          commit('setProfileData', profileData);
        } else {
          if (state.isAuthenticated) {
            dispatch('logout');
          }
        }
      } catch (e) {
        /*
          api.fetchProfile() обрабатывает все ошибки внутри, а возвращает только false поэтому данный блок не выполняется
         */
        dispatch('gohome');
      } finally {
        dispatch("hideLoader");
      }
    },
    async fetchCigaretteMarks({commit, dispatch}) {
      dispatch("showLoader");
      try {
        const result = await api.fetchCigaretteMarks();

        if (result) {
          commit('setStateData', {state: 'cigaretteMarks', data: result});
        }
      } catch (e) {
        dispatch('setErrors', e);
      } finally {
        dispatch("hideLoader");
      }
    },
    async fetchUserSegments({commit, dispatch}) {
      try {
        const userSegments = await api.simplePostRequest('/api/checkSegmentations');
        if (userSegments) {
          commit('setUserSegments', userSegments);
        }
      } catch (e) {
        dispatch('setErrors', e);
      }
    },
  },
  getters: {
    isAgeConfirmed: (state) => state.ageConfirmed
  },
  modules: {
  },
  plugins: [
    vuexLocal.plugin
  ]
})
